.item__container {
    background-color: rgb(246, 246, 246);
    /* margin-top: 5px; */
    margin: 10px 15px 0;
    padding: 0;
    border-radius: 15px;
    font-size: .9em;
    /* padding: 0px 15px; */
    min-height: 90px;
    padding: 15px;
    text-align: left;
    box-sizing: border-box;
}

.lite-deadline {
    border: 2px solid rgb(252, 150, 55);
    /* background-color: #ff03034b; */
}

.hard-deadline {
    border: 3px solid red;
    /* background-color: #ff03034b; */
}

.item__container .item__container__general {
    /* background: rgb(214, 137, 137); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100%; */
    /* margin: 10px 0px; */
}

.item__container .arrow-up-button {
    width: 20px;
    height: auto;
    background: transparent;
    border: none;
    padding: 0;
    position: relative;
    top: -5px;
}

.item__container .arrow-up-button img {
    width: 100%;
}

.action-buttons {
    margin: 20px auto;
    display: flex;
    justify-content: center;
}

.item__container .action-buttons button {
    width: 200px;
    height: 60px;
    padding: 0;
    /* border: 1px solid #dedede; */
    border: none;
    border-radius: 5px;
    margin: 0px 5px;
    filter: drop-shadow(1px 2px 2px grey);
}

.item__container .action-buttons>button>img {
    width: auto;
    height: 15px;
}

.item__container__detail li {
    list-style: none;
    margin: 2px 0;
}

.item__container .master-message {
    width: 100%;
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid grey;
    margin-bottom: 30px;
}

.deal-detail-block .deal-param {
    margin-left: 3px;
}

.deal-detail-block .deal-param-value {
    color: #074ed4;
    margin-left: 5px;
}

.deal-detail-block .product-index {
    background: #4174dd24;
    width: fit-content;
    margin-bottom: 5px;
}

.deal-detail-block .cloud-message {
    overflow: hidden;
}

.feedback label {
    margin-left: 5px;
}


/* .feedback input[type="button"] {
    margin: 10px 0;
} */

.feedback input[type="text"],
.feedback input[type="submit"],
.feedback button {
    width: 100%;
    min-height: 30px;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 10px;
}

.item__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}

.item__data {
    text-align: left;
}

.item__title>div {
    display: inline-flex;
}

.overtask > span{
    padding: 1px 4px;
    font-size: 14px;
    border-radius: 3px;
}

.calendar-list-date {
    /* font-weight: bold; */
    margin: 30px;
    background-color: rgb(246, 246, 246);
    /* margin-top: 5px; */
    margin: 10px 15px 0;
    padding: 0;
    border-radius: 15px;
    font-size: 1.2em;
    /* padding: 0px 15px; */
    /* min-height: 40px; */
    padding: 25px;
    /* text-align: left; */
}