.header {
    width: 100%;
    /* max-width: 350px; */
    max-height: 200px;
    background: linear-gradient(180deg, #71D6C9 0%, rgb(201 227 243) 100%);
    border-radius: 0px 0px 15px 15px;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 9999;
}

.header .logout-button,
.header .finished-hide-button {
    background: none;
    border: navajowhite;
    position: absolute;
    top: 10px;
    outline: none;
}

.header .logout-button {
    right: 10px;
}

.header .finished-hide-button {
    left: 10px;
}

.head-search {
    display: flex;
    align-items: center;
    position: relative;
}

.super-search-button {
    padding: 3px;
    width: 100%;
    margin-top: 3px;
    border-radius: 10px;
    border: 1px solid grey;
}

.head-search>input {
    padding: 5px 20px;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid rgb(187, 187, 187);
    background: #ffffff8f;
    border: 1px solid grey;
    border-radius: 10px;
    position: relative;
    outline: none;
}

.head-search>.clear-search {
    border-radius: 50%;
    border-radius: 50%;
    border: 1px solid rgb(187, 187, 187);
    background: transparent;
    border: none;
    width: 20px;
    height: 20px;
    color: grey;
    line-height: 0;
    position: absolute;
    right: 5px;
    outline: none;
}


/* .head-search::before {
    content: "🔍";
    position: absolute;
    margin-left: 5px;
} */

.header__animation::after {
    content: " ";
    width: 10px;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: white;
    filter: blur(5px);
    animation: loader 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite normal;
    transition: .5 s;
}

@keyframes loader {
    from {
        margin-left: -80px;
        opacity: 0;
        /* height: 0px; */
    }
    25% {
        /* height: 60px; */
        opacity: 1;
    }
    50% {
        margin-left: calc(100%);
        opacity: 0;
        /* height: 0px; */
    }
    75% {
        /* height: 60px; */
        opacity: 1;
    }
    to {
        margin-left: -80px;
        /* height: 0px; */
    }
}