@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap);
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* Общие CSS */
.container {
    max-width: 480px;
    margin: 0 auto;
    height: 100vh;
    max-height: 600px;
    /* border: 1px solid grey; */
    text-align: center;
}

.form-input {
    width: 264px;
    height: 46px;
    text-align: center;
    border: 1px solid #C1C1C1;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: .95em;
}

.big-transparent-button {
    padding: 0;
    height: 44px;
    width: 44px;
    background: transparent;
    background-size: cover;
    background-position: center;
    border: none;
}

button,
.cursor-pointer {
    cursor: pointer;
}

.task-status-temp {
    background-color: #77CFEB;
}

.task-status-wait {
    background-color: #AAEB77;
}

.task-status-repair,
.task-status-pause {
    background-color: #EBD877;
}

.task-status-finished {
    background-color: #BFBFBF;
}

.task-status-dedline {
    background-color: #EB7777;
}

.inactive-button {
    opacity: .3;
}

.padding-box {
    /* padding: 2px 10px 1px; */
    padding-left: 8px;
    padding-right: 8px;
    line-height: 1.4;
    border-radius: 5px;
    padding-top: 2px;
}

.info-symbol {
    height: 20px;
    /* width: 20px; */
    line-height: 1.5;
    padding: 0px 10px;
    text-align: center;
    flex-shrink: 0;
    margin-right: 5px;
}

.info-symbol__manager-info {
    border: 2px solid rgb(170, 235, 119);
}

.info-symbol__stuck {
    border: 2px solid #EBD877;
    background-color: #EBD877;
}

.cloud-message {
    background: white;
    font-size: .9em;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.item__container {
    background-color: rgb(246, 246, 246);
    /* margin-top: 5px; */
    margin: 10px 15px 0;
    padding: 0;
    border-radius: 15px;
    font-size: .9em;
    /* padding: 0px 15px; */
    min-height: 90px;
    padding: 15px;
    text-align: left;
    box-sizing: border-box;
}

.lite-deadline {
    border: 2px solid rgb(252, 150, 55);
    /* background-color: #ff03034b; */
}

.hard-deadline {
    border: 3px solid red;
    /* background-color: #ff03034b; */
}

.item__container .item__container__general {
    /* background: rgb(214, 137, 137); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100%; */
    /* margin: 10px 0px; */
}

.item__container .arrow-up-button {
    width: 20px;
    height: auto;
    background: transparent;
    border: none;
    padding: 0;
    position: relative;
    top: -5px;
}

.item__container .arrow-up-button img {
    width: 100%;
}

.action-buttons {
    margin: 20px auto;
    display: flex;
    justify-content: center;
}

.item__container .action-buttons button {
    width: 200px;
    height: 60px;
    padding: 0;
    /* border: 1px solid #dedede; */
    border: none;
    border-radius: 5px;
    margin: 0px 5px;
    -webkit-filter: drop-shadow(1px 2px 2px grey);
            filter: drop-shadow(1px 2px 2px grey);
}

.item__container .action-buttons>button>img {
    width: auto;
    height: 15px;
}

.item__container__detail li {
    list-style: none;
    margin: 2px 0;
}

.item__container .master-message {
    width: 100%;
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid grey;
    margin-bottom: 30px;
}

.deal-detail-block .deal-param {
    margin-left: 3px;
}

.deal-detail-block .deal-param-value {
    color: #074ed4;
    margin-left: 5px;
}

.deal-detail-block .product-index {
    background: #4174dd24;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 5px;
}

.deal-detail-block .cloud-message {
    overflow: hidden;
}

.feedback label {
    margin-left: 5px;
}


/* .feedback input[type="button"] {
    margin: 10px 0;
} */

.feedback input[type="text"],
.feedback input[type="submit"],
.feedback button {
    width: 100%;
    min-height: 30px;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 10px;
}

.item__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}

.item__data {
    text-align: left;
}

.item__title>div {
    display: inline-flex;
}

.overtask > span{
    padding: 1px 4px;
    font-size: 14px;
    border-radius: 3px;
}

.calendar-list-date {
    /* font-weight: bold; */
    margin: 30px;
    background-color: rgb(246, 246, 246);
    /* margin-top: 5px; */
    margin: 10px 15px 0;
    padding: 0;
    border-radius: 15px;
    font-size: 1.2em;
    /* padding: 0px 15px; */
    /* min-height: 40px; */
    padding: 25px;
    /* text-align: left; */
}
.header {
    width: 100%;
    /* max-width: 350px; */
    max-height: 200px;
    background: linear-gradient(180deg, #71D6C9 0%, rgb(201 227 243) 100%);
    border-radius: 0px 0px 15px 15px;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 9999;
}

.header .logout-button,
.header .finished-hide-button {
    background: none;
    border: navajowhite;
    position: absolute;
    top: 10px;
    outline: none;
}

.header .logout-button {
    right: 10px;
}

.header .finished-hide-button {
    left: 10px;
}

.head-search {
    display: flex;
    align-items: center;
    position: relative;
}

.super-search-button {
    padding: 3px;
    width: 100%;
    margin-top: 3px;
    border-radius: 10px;
    border: 1px solid grey;
}

.head-search>input {
    padding: 5px 20px;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid rgb(187, 187, 187);
    background: #ffffff8f;
    border: 1px solid grey;
    border-radius: 10px;
    position: relative;
    outline: none;
}

.head-search>.clear-search {
    border-radius: 50%;
    border-radius: 50%;
    border: 1px solid rgb(187, 187, 187);
    background: transparent;
    border: none;
    width: 20px;
    height: 20px;
    color: grey;
    line-height: 0;
    position: absolute;
    right: 5px;
    outline: none;
}


/* .head-search::before {
    content: "🔍";
    position: absolute;
    margin-left: 5px;
} */

.header__animation::after {
    content: " ";
    width: 10px;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: white;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    animation: loader 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite normal;
    transition: .5 s;
}

@keyframes loader {
    from {
        margin-left: -80px;
        opacity: 0;
        /* height: 0px; */
    }
    25% {
        /* height: 60px; */
        opacity: 1;
    }
    50% {
        margin-left: calc(100%);
        opacity: 0;
        /* height: 0px; */
    }
    75% {
        /* height: 60px; */
        opacity: 1;
    }
    to {
        margin-left: -80px;
        /* height: 0px; */
    }
}
            .login-container {
                width: 85%;
                height: 100%;
                margin: 0 auto;
                text-align: center;
            }
            
            .login-container__form {
                margin-top: 400px;
            }
            
            .login-container__form>input {
                margin-bottom: 10px;
            }
            
            .header>img {
                width: 230px;
                position: absolute;
                left: 0px;
                right: 0px;
                bottom: 30px;
                margin: 0 auto;
            }
