            .login-container {
                width: 85%;
                height: 100%;
                margin: 0 auto;
                text-align: center;
            }
            
            .login-container__form {
                margin-top: 400px;
            }
            
            .login-container__form>input {
                margin-bottom: 10px;
            }
            
            .header>img {
                width: 230px;
                position: absolute;
                left: 0px;
                right: 0px;
                bottom: 30px;
                margin: 0 auto;
            }