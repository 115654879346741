body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* Общие CSS */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');
.container {
    max-width: 480px;
    margin: 0 auto;
    height: 100vh;
    max-height: 600px;
    /* border: 1px solid grey; */
    text-align: center;
}

.form-input {
    width: 264px;
    height: 46px;
    text-align: center;
    border: 1px solid #C1C1C1;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: .95em;
}

.big-transparent-button {
    padding: 0;
    height: 44px;
    width: 44px;
    background: transparent;
    background-size: cover;
    background-position: center;
    border: none;
}

button,
.cursor-pointer {
    cursor: pointer;
}

.task-status-temp {
    background-color: #77CFEB;
}

.task-status-wait {
    background-color: #AAEB77;
}

.task-status-repair,
.task-status-pause {
    background-color: #EBD877;
}

.task-status-finished {
    background-color: #BFBFBF;
}

.task-status-dedline {
    background-color: #EB7777;
}

.inactive-button {
    opacity: .3;
}

.padding-box {
    /* padding: 2px 10px 1px; */
    padding-left: 8px;
    padding-right: 8px;
    line-height: 1.4;
    border-radius: 5px;
    padding-top: 2px;
}

.info-symbol {
    height: 20px;
    /* width: 20px; */
    line-height: 1.5;
    padding: 0px 10px;
    text-align: center;
    flex-shrink: 0;
    margin-right: 5px;
}

.info-symbol__manager-info {
    border: 2px solid rgb(170, 235, 119);
}

.info-symbol__stuck {
    border: 2px solid #EBD877;
    background-color: #EBD877;
}

.cloud-message {
    background: white;
    font-size: .9em;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}